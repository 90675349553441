import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { LogoIcon } from "src/assets/svg";
import { useProtectedRoutesContext } from "src/context/ProtectedRoutes";

function AuthLayout() {
	const { user, token } = useProtectedRoutesContext();

	if (!!user && !!token) return <Navigate to="/" />;

	return (
		<div className="flex flex-col min-h-screen justify-center items-center max-w-[1536px] mx-auto ">
			<div className="mt-[2rem]">
				<LogoIcon />
			</div>
			<div className="flex items-center justify-center flex-grow ">
				<Outlet />
			</div>
			<div className="text-[1.6rem] text-gray mt-[2rem]">©{new Date().getFullYear()} Engage Copilot. All rights reserved</div>
		</div>
	);
}

export const BannerComponent = () => {
	return (
		<div className="authBg hidden md:block lg:min-w-[45rem] ">
			{/* <img src={Banner} alt="ecp-banner" width="500px" className='hidden h-auto md:block' /> */}
		</div>
	);
};

export default AuthLayout;
