import { ReactComponent as LogoIcon } from "./logo.svg";
import { ReactComponent as SmallLogoIcon } from "./small-logo.svg";
import { ReactComponent as ConfigurationIcon } from "./configuration.svg";
import { ReactComponent as DashboardIcon } from "./dashboard.svg";
import { ReactComponent as OptionsIcon } from "./options.svg";
import { ReactComponent as OrdersIcon } from "./orders.svg";
import { ReactComponent as RightArrowHeadIcon } from "./right-arrowhead.svg";
import { ReactComponent as SearchIcon } from "./search.svg";
import { ReactComponent as SecurityIcon } from "./security.svg";
import { ReactComponent as SidebarIcon } from "./sidebar.svg";
import { ReactComponent as TicketsIcon } from "./tickets.svg";
import { ReactComponent as UserIcon } from "./user.svg";
import { ReactComponent as UsersIcon } from "./users.svg";
import { ReactComponent as AdminIcon } from "./admins.svg";
import { ReactComponent as ClientsIcon } from "./clients.svg";
import { ReactComponent as BurgerIcon } from "./burger.svg";
import { ReactComponent as PDFIcon } from "./pdf.svg";
import { ReactComponent as ImageIcon } from "./image.svg";
import { ReactComponent as NoDataIcon } from "./no-data.svg";
import { ReactComponent as EyeIcon } from "./eye.svg";
import { ReactComponent as DownloadIcon } from "./download.svg";
import { ReactComponent as PaystackIcon } from "./paystack.svg";
import { ReactComponent as StripeIcon } from "./stripe.svg";
import { ReactComponent as LeftArrowIcon } from "./left-arrow.svg";
import { ReactComponent as RightArrowIcon } from "./right-arrow.svg";
import { ReactComponent as LogoNoText } from "./logo-no-text.svg";
import { ReactComponent as FilterIcon } from "./filter.svg";
import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as AddIcon } from "./add.svg";
import { ReactComponent as TrashIcon } from "./trash.svg";
import { ReactComponent as SendIcon } from "./send.svg";
import { ReactComponent as DeleteIcon } from "./delete.svg";
import { ReactComponent as EditIcon } from "./edit.svg";
import { ReactComponent as User2Icon } from "./user2.svg";
import { ReactComponent as FlutterwaveIcon } from "./flutterwave.svg";
import { ReactComponent as ShiipIcon } from "./shiip.svg";
import { ReactComponent as TopShiipIcon } from "./topship.svg";
import { ReactComponent as WhatsAppIcon } from "./whatsapp.svg";
import { ReactComponent as SMSIcon } from "./sms.svg";
import { ReactComponent as SubscriptionIcon } from "./subscriptions.svg";

export {
	LogoIcon,
	SmallLogoIcon,
	ConfigurationIcon,
	DashboardIcon,
	OptionsIcon,
	OrdersIcon,
	RightArrowHeadIcon,
	SearchIcon,
	SecurityIcon,
	SidebarIcon,
	TicketsIcon,
	UserIcon,
	UsersIcon,
	AdminIcon,
	ClientsIcon,
	BurgerIcon,
	PDFIcon,
	ImageIcon,
	NoDataIcon,
	EyeIcon,
	DownloadIcon,
	PaystackIcon,
	StripeIcon,
	LeftArrowIcon,
	RightArrowIcon,
	LogoNoText,
	FilterIcon,
	CloseIcon,
	AddIcon,
	TrashIcon,
	SendIcon,
	DeleteIcon,
	EditIcon,
	User2Icon,
	FlutterwaveIcon,
	ShiipIcon,
	TopShiipIcon,
	WhatsAppIcon,
	SMSIcon,
	SubscriptionIcon,
};
