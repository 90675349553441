import Dashboard from "./Dashboard";
import Configuration from "./Configuration";
import Orders from "./Orders";
import Security from "./Security";
import Tickets from "./Tickets";
import Clients from "./Clients";
import Admins from "./Admins";
import Subscriptions from "./Subscriptions";

export { Dashboard, Configuration, Orders, Security, Tickets, Clients, Admins, Subscriptions };
