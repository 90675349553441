import React, { useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import {
	AdminIcon,
	ClientsIcon,
	ConfigurationIcon,
	DashboardIcon,
	LogoIcon,
	OrdersIcon,
	SidebarIcon,
	SmallLogoIcon,
	SubscriptionIcon,
	UsersIcon,
} from "src/assets/svg";
import { Profile } from "./TopNav";

const SideBar = () => {
	const { pathname } = useLocation();
	const [collapsed, setCollapsed] = useState(false);

	const routes = [
		{ path: "/", icon: <DashboardIcon />, name: "Dashboard" },
		{
			icon: <UsersIcon />,
			name: "Manage Users",
			children: [
				{
					path: "/clients",
					name: "SMB Clients",
					icon: <ClientsIcon />,
				},
				{
					path: "/admins",
					name: "Admins",
					icon: <AdminIcon />,
				},
			],
		},
		{ path: "/orders", icon: <OrdersIcon />, name: "Manage Orders" },
		{ path: "/subscriptions", icon: <SubscriptionIcon />, name: "Subscriptions" },
		{ path: "/configuration", icon: <ConfigurationIcon />, name: "Configuration" },
	];

	return (
		<Sidebar defaultCollapsed={collapsed} backgroundColor="#F9FAFB" width="260px" collapsedWidth="75px" customBreakPoint="1000px">
			<Menu closeOnClick>
				<div className="flex items-center justify-between mb-10">
					<div className="flex items-center gap-2">
						{collapsed ? <SmallLogoIcon /> : <LogoIcon className="w-[70px]" />}
						{!collapsed && (
							<p className="uppercase text-[#05050533] text-[1.2rem] font-semibold border border-[#05050533] px-[6px] py-[1px] rounded-md">
								admin
							</p>
						)}
					</div>
					<button className="max-[1000px]:hidden" onClick={() => setCollapsed((prevState) => !prevState)}>
						<SidebarIcon className={collapsed ? "rotate-180 transition-transform" : "transition-transform"} />
					</button>
				</div>
				{!collapsed && <p className="uppercase text-[1.2rem] font-semibold text-[#9CA3AF] mb-[1.2rem]">Menu</p>}
				{routes.map(({ path, name, icon, children }, index) => {
					if (children)
						return (
							<SubMenu key={index} icon={icon} label={name} data-isCustomCollapsed={collapsed}>
								{children.map(({ path, name, icon }, childIndex) => (
									<MenuItem key={`sub-${childIndex}`} component={<Link to={path} />} active={pathname.endsWith(path)} icon={icon}>
										{name}
									</MenuItem>
								))}
							</SubMenu>
						);
					return (
						<MenuItem key={index} component={<Link to={path} />} active={pathname.endsWith(path)} icon={icon}>
							{name}
						</MenuItem>
					);
				})}
			</Menu>
			{!collapsed && (
				<div className="px-[2rem] border-t border-lightGray pt-[2rem]">
					<Profile />
				</div>
			)}
		</Sidebar>
	);
};

export default SideBar;
