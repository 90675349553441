import React from 'react';
import { Button } from "src/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";

const renderItems = (items) => {
    return items.map((item, index) => (
        <div key={index}>
            {item.label === 'separator' ? (
                <DropdownMenuSeparator />
            ) : (
                <DropdownMenuItem disabled={item.disabled}>
                    {item.icon && <span className="mr-2">{item.icon}</span>}
                    {item.label}
                    {item.shortcut && <DropdownMenuShortcut>{item.shortcut}</DropdownMenuShortcut>}
                    {item.subItems && (
                        <DropdownMenuSub>
                            <DropdownMenuSubTrigger>{item.label}</DropdownMenuSubTrigger>
                            <DropdownMenuPortal>
                                <DropdownMenuSubContent>
                                    {renderItems(item.subItems)}
                                </DropdownMenuSubContent>
                            </DropdownMenuPortal>
                        </DropdownMenuSub>
                    )}
                </DropdownMenuItem>
            )}
        </div>
    ));
};

const CustomDropdown = ({ items, buttonVariant = 'outline', buttonContent, label }) => {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant={buttonVariant}>{buttonContent}</Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
                {label && <DropdownMenuLabel>{label}</DropdownMenuLabel>}
                {label && <DropdownMenuSeparator />}
                <DropdownMenuGroup>
                    {renderItems(items)}
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    );
};

export default CustomDropdown;
