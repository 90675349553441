// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tiptap_tiptap__\\+eVdu {
	margin-top: 0.75em;
}

h1 .tiptap_heading1__LggqF {
	font-size: 24px;
	line-height: 1.1;
}
`, "",{"version":3,"sources":["webpack://./src/components/tiptap.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;AACnB;;AAEA;CACC,eAAe;CACf,gBAAgB;AACjB","sourcesContent":[".tiptap {\n\tmargin-top: 0.75em;\n}\n\nh1 .heading1 {\n\tfont-size: 24px;\n\tline-height: 1.1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tiptap": `tiptap_tiptap__+eVdu`,
	"heading1": `tiptap_heading1__LggqF`
};
export default ___CSS_LOADER_EXPORT___;
